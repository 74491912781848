
@media screen and (max-width: 470px) {
  
    .buttons{
        font-size: 13px;
    }

    .table-container {
        overflow-x: auto;
        max-width: 100%;
      }
      .tabs-container {
        position: sticky;
        top: 0;
        background-color: #ffffff; /* Change this to your background color */
        z-index: 100;
      }
    
  }