@media screen and (max-width: 420px) {

    .main_body {
        gap: 0 !important;
    }

    /* .content_body {
        width: 154px;
    } */

    .text_area {
        left: 0 !important;
        overflow: auto;
    }

    .save_button {
        width: 100%;
    }
}