
@media screen and (max-width: 420px) {
  
    .header_right{
        width: 150px !important;
    } 
  
    .bottom_text{
        margin-top: 0px !important;
    } 
    .top_text{
        margin-bottom: 0px !important;  
    }
    .text-size{
       font-size: 9px;
    }
  }