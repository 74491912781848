/* Font Configuration */

@import url("https://fonts.googleapis.com/css2?family=Fredoka&family=Source+Code+Pro:wght@300&display=swap");

/* Tailwind Configuration  */

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  display: none;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #f6f6f6 !important;
  font-family: Fredoka !important;
  font-weight: 400 !important;
  min-height: 100vh;
  .lowerZindex{
    /* z-index: 200 !important; */
    font-family: Fredoka !important;
    direction: ltr;
    display: block;
    opacity: 1;
    text-align: center;
    z-index: 20 !important;
  }
}

.loginpage {
  background-color: white !important;
  border: 2px solid rgba(0, 158, 248, 0.81) !important;
}

.border-3 {
  border: 2px solid rgba(0, 158, 248, 0.81) !important;
}

.linebar {
  color: #1a73e8 !important;
  margin: 0 4px 0 4px !important;
}

.buttonColor {
  border-radius: 8px none !important;
  border-style: none !important;
  background: rgba(0, 248, 214, 0.5) !important;
}

#root {
  min-height: 100vh;
}

.min-h-300 {
  min-height: 300px;
}

.min-h-500 {
  min-height: 500px;
}

.loader-outer {
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: black;
  opacity: 0.2;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #bbbbbb;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

textarea:focus,
input:focus {
  outline: 0;
}

*:focus {
  outline: 0;
}


#st-1{
  z-index: 12 !important;
}

.black {
  border: 2px solid rgb(14, 1, 1);
}

.blue {
  border: 3px solid rgb(24, 10, 226);
}

.red {
  border: 2px solid rgb(226, 34, 9);
}
