textarea::-webkit-scrollbar {
  width: 1px;

}

textarea::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}

textarea::-webkit-scrollbar-thumb {
  background-color: white;
  outline: none;
}

textarea {
  resize: none;
}

textarea {
  /* min-height: 20rem; */
  max-height: 20rem;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  resize: none; /* disable resizing */
}

.active {
  color: slateblue;
  text-decoration: underline;
}

/* // Pagination css  */

.pagination {
  list-style: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 12px;
  gap: 5px;
  margin-top: 10px;
}
.pagination .page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 8px;
  font-weight: 400;
  border: 1px solid rgb(178, 175, 175);
}
.pagination .page-num:hover {
  background-color: blueviolet;
  color: white;
}
.pagination .page-nav {
  background: rgb(178, 175, 175);
  border-radius: 8px;
  /* padding: 8px; */
  padding: 8px 16px;
  color: white;
  font-weight: 500;
}
.pagination .active {
  border: 2px solid blueviolet;
  border-radius: 8px;
}

/* Number input  */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.scrollable-container {
  scrollbar-width: none; /* Hide the scrollbar */
  scrollbar-color: transparent transparent; /* Hide the scrollbar thumb and track */
}

.share-container + div {
  display: block !important;
}

.red-underline {
  border-bottom: 2px solid red;
}