@media screen and (max-width: 470px) {
  .summary_heading {
    font-size: 12px;
    word-wrap: break-word;
  }

  .summary_box {
    font-size: 16px;
    word-wrap: break-word;
  }

  .summary_footer {
    flex-direction: row;
  }

  .credit {
    font-size: 12px;
  }

  .manager_button {
    width: 180px;
  }

  .summary_button {
    padding: 4px;
  }

  .share_button {
    border: 1px solid #dbdfe2;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .share_icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 375px) {
  .summary_heading {
    font-size: 12px;
    word-wrap: break-word;
  }

  .summary_box {
    font-size: 16px;
    word-wrap: break-word;
  }

  .summary_footer {
    flex-direction: row;
  }

  .credit {
    font-size: 12px;
  }

  .manager_button {
    width: 180px;
  }

  .summary_button {
    padding: 4px;
  }

  .share_button {
    border: 1px solid #dbdfe2;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .share_icon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 767px) {
  .share-button {
    padding: 0.3rem; /* Adjust padding for smaller screens */
    margin-left: 0.3rem; /* Adjust margin for smaller screens */
  }

  .share-icon {
    /* Adjust the size of the icon for smaller screens */
    width: 20px;
    height: 20px;
  }

  .edit_template {
    right: 1%;
    max-width: 98%; /* Adjust max-width as needed */
  }

  .edit_template button {
    width: 100%; /* Make the button full-width */
    max-width: none; /* Reset max-width for the button */
    margin-bottom: 10px; /* Adjust margin for spacing */
  }
}

.step-card {
  display: flex;
  justify-content: space-around;
  background-color: #f9f9f9;
  padding: 20px;
}

.step {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 182px;
}

.step h3 {
  font-family: Fredoka;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  margin-top: 10px;
}
